function isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}

$(document).ready(function () {

    var form = $('.form-parsley');

    form.submit(function (e) {
        e.preventDefault();
    });

    $("body").addClass("loaded");
    $("#distinto").delay(3000).fadeIn(function(){
        $("#preloader").fadeOut();
        $(window).resize();
    });

    if (form.length) {
        form.parsley().on('field:validated', function () {
            var ok = $('.parsley-error').length === 0;
            $('.bs-callout-info').toggleClass('hidden', !ok);
            $('.bs-callout-warning').toggleClass('hidden', ok);
        })
        .on('form:submit', function () {
            // $('.submit-btn').attr('disabled', true);
            // return true;
            var url = form.attr('action');
            var data = form.serialize();
            var method = $('input[name="_method"]').val();
            var type = (_.isUndefined(method) ? 'POST' : method);
            console.log(data);
            $('.submit-btn').prop('disabled', true);
            
            $.ajax({
                type: type,
                url: url,
                data: data,
                dataType: 'json',
                processData: false,
                success: function (data) {
                    var title = data.notifTitle;
                    var message = '';
                    // var message = (_.isUndefined(data.notifMessage) ? '' : data.notifMessage);
                    if (title) {
                        showNotify(title, message);
                    }
                    if (!_.isUndefined(data.redirect)) {
						window.location = data.redirect;
                    }
                    resetForm(form);
                },
                error: function (data, text, error) {
                    var message = '';
                    _.each(data.responseJSON, function (val) {
                        message += val + ' ';
                    });
                    showNotify('Error saving.', message);
                }
            });

            return false;
        });
    }

    function resetForm(form) {
        form.find('input').val('');
        form.find('textarea').val('');
        form.find('select').val('');
        form.find('.submit-btn').prop('disabled', false);
        $('.error-container').text('');
    }

    function showNotify(title, message, timer) {
        message = (_.isUndefined(message) ? '' : message);
        timer = (_.isUndefined(timer) ? 5000 : timer);
        swal({
            title: title,
            text: message,
            timer: timer,
            confirmButtonColor: '#ffc805',
            showCloseButton: true,
            showConfirmButton: true
        });
        // $('.modal-body').html(message);
        // $('#modalTH').modal('show');
    }

});